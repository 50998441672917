<template>

        <template class="header_socials">

                <a target="_blank" href="https://www.linkedin.com/in/hugo-garelha/">
                        <v-icon name="bi-linkedin"/>
                </a>

                <a target="_blank" href="https://github.com/Kail-the-akuma/">
                        <v-icon name="bi-github"/>
                </a>

                <a target="_blank" href="https://www.behance.net/Hugo_garelha">
                        <v-icon name="bi-behance"/>
                </a>

                <a target="_blank" href="https://hugogarelha.itch.io/">
                        <v-icon name="fa-itch-io"/>
                </a>


        </template>
   
</template>

<script>




export default{

components:{

}

}

</script>

<!---------------------------------------------------------------------->
