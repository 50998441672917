<template>

<div id='Experience' className="exp_container">
      <h5>My Skills</h5>
      <h2>Experience</h2>

      <div className='container experience_container'>
       
        <!--=================== CODING =============-->
        <div className='experience_code'>
                  <h3>Coding</h3>
          <article className='experience_details'>
            <v-icon name="si-csharp" className='skill_list-icon'/>
 
            <div classname ="experience_details-text">
              <h4>C#</h4>
            </div>
          </article>

          <article className='experience_details'>
            <v-icon name="co-cplusplus" className='skill_list-icon'/>
        
            <div classname ="experience_details-text">
              <h4>C++</h4>
            </div>
          </article>
            
          <article className='experience_details'>
            <v-icon name="fa-html5" className='skill_list-icon'/>

            <div classname ="experience_details-text">
             <h4>HTML</h4>              
            </div>
          </article>

          <article className='experience_details'>
            <v-icon name="fa-css3-alt" className='skill_list-icon'/>
  
            <div classname ="experience_details-text">
            <h4>CSS</h4>             
            </div>
          </article>

          <article className='experience_details'>
            <v-icon name="co-mysql" className='skill_list-icon'/>

            <div classname ="experience_details-text">
              <h4>SQL</h4>             
            </div>
          </article>


          <article className='experience_details'>
            <v-icon name="fa-vuejs" className='skill_list-icon'/>

            <div classname ="experience_details-text">
              <h4>VueJs</h4>  
            </div>
          </article>

          <article className='experience_details'>
            <v-icon name="fa-react" className='skill_list-icon'/>

            <div classname ="experience_details-text">
              <h4>React</h4>  
            </div>

          </article>
          <article className='experience_details'>
            
            <v-icon name="fa-regular-circle" className='skill_list-icon'/>
            <div classname ="experience_details-text">
              <h4>Outsystems</h4>  
            </div>
          </article>

          <article className='experience_details'>
          <v-icon name="io-logo-firebase" className='skill_list-icon'/>
            <div classname ="experience_details-text">
              <h4>FireBase</h4>  
            </div>
          </article>

        </div>

 <!--=================== GAME DEV =============-->
 <div className='experience_Gamedev'>
          <h3>Game Dev</h3>

          <article className='experience_details'>
            <v-icon name="co-unity" className='skill_list-icon'/>
            <div classname ="experience_details-text">
              <h4>Unity</h4>
              <p>2D and 3D Game Dev</p>              
            </div>
          </article>

          <article className='experience_details'>
            <v-icon name="co-unreal-engine" className='skill_list-icon'/>
            <div classname ="experience_details-text">
              <h4>Unreal Engine</h4>
              <p>3D and VR Game Dev</p>              
            </div>
          </article>

          <article className='experience_details'>
            <v-icon name="co-krita" className='skill_list-icon'/>
            <div classname ="experience_details-text">
              <h4>Krita</h4>
              <p>Illustration and 2D Animation</p>              
            </div>
          </article>

          <article className='experience_details'>
            <v-icon name="co-blender" className='skill_list-icon'/>
            <div classname ="experience_details-text">
              <h4>Blender</h4>
              <p>3D Modeling and Animation</p>              
            </div>
          </article>

        </div>

        <!--=================== DESIGN =============-->
        <div className='experience_design'>
          <h3>Design and Art</h3>
          <article className='experience_details'>
          <v-icon name="si-adobeillustrator" className='skill_list-icon'/>
            <div>
              <h4>Adobe Illustrator</h4>
              <p>Graphic Design</p>              
            </div>
          </article>

          <article className='experience_details'>
            <v-icon name="si-adobephotoshop" className='skill_list-icon'/>
            <div classname ="experience_details-text">
              <h4>Adobe Photoshop</h4>
              <p>Graphic Design and Illustration</p>              
            </div>
          </article>

          <article className='experience_details'>
            <v-icon name="si-adobexd" className='skill_list-icon'/>
            <div classname ="experience_details-text">
              <h4>Adobe XD</h4>
              <p>UX/UI Design</p>              
            </div>
          </article>

          <article className='experience_details'>
            <v-icon name="si-adobepremierepro" className='skill_list-icon'/>
            <div classname ="experience_details-text">
              <h4>Adobe Premiere</h4>
              <p>Video Editing</p>              
            </div>
          </article>

        </div>
      </div>
    </div>
 
</template>

<script>

import "./SkillsCardStyle.css"

export default{

}
</script>