<template>
  
  <div className="container edit-dash">
        <h1> Dash</h1>

    </div>


</template>

<script>
import './DashboardStyle.css'


export default{

  name:"DashboardCard",

}

</script>

