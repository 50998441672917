<template>

    <div className='cta'>

        <a href='../../assets/Resume_HugoGarelha.pdf' download>
            <button className='btn'> 
                Download Resume
            </button>
        </a>

        <router-link  to="/contacts" >           
            <button className='btn btn-primary'>
                Talk To Me
            </button>
        </router-link>

    </div>

</template>
