<template>
    <LoginComponent/>
</template>

<script>

import LoginComponent from '../EditPanel/LoginComponent.vue'

export default {
    name: 'Login-Component',
    components: {
      LoginComponent
    }
  }
</script>